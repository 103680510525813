<template>
  <!-- 互动区位 -->
  <div class="zone-page">
    <div class="submenu" :class="{ close: openMenu==false }">
      <a class="item" :class="{ active : zone== 0 }" @click="toSlide(0)" href="javascript:;">交通</a>
      <a class="item" :class="{ active : zone== 1 }" @click="toSlide(1)" href="javascript:;">商业</a>
      <a class="item" :class="{ active : zone== 2 }" @click="toSlide(2)" href="javascript:;">医疗</a>
      <a class="item" :class="{ active : zone== 3 }" @click="toSlide(3)" href="javascript:;">教育</a>
      <a class="item" :class="{ active : zone== 4 }" @click="toSlide(4)" href="javascript:;">公园</a>
      <div class="box"></div>
    </div>
    <div class="zone">
      <div class="bg">
        <div class="tips"></div>
      </div>
			<swiper class="swiper" :options="swiperOption" ref="mySwiper">
				<swiper-slide>
					<div class="metro">
						<div class="line"></div>
						<div class="station"></div>
						<div class="tips"></div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="emporia">
						<div class="radar1">
						</div>
						<div class="radar2">
						</div>
						<div class="radar3">
						</div>
						<div class="tips"></div>

						<div class="e6">IFS国际金融中心<div class="point"></div></div>
						<div class="e7">成都远洋太古里<div class="point"></div></div>
						<div class="e8">武侯生活广场<div class="point"></div></div>
						<div class="e9">鹭岛商业街<div class="point"></div></div>

						<div class="e1">红牌楼广场<div class="point" @click="box.e1=true"></div></div>
						<div class="e2">伊藤洋华堂<small>（双楠店）</small><div class="point" @click="box.e2=true"></div></div>
						<div class="e3">大悦城<div class="point" @click="box.e3=true"></div></div>
						<div class="e4"><span>莱蒙都会</span><div class="point" @click="box.e4=true"></div></div>
						<div class="e5">本案商业街区<div class="point" @click="box.e5=true"></div></div>

					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="hospital">
						<div class="tips"></div>
						<div class="h1">双楠医院<div class="rpoint" @click="box.h1=true"></div></div>
						<div class="h2">西南儿童医院<div class="rpoint" @click="box.h2=true"></div></div>
						<div class="h3">武侯区人民医院<div class="lpoint" @click="box.h3=true"></div></div>
						<div class="h4">华西医院<div class="lpoint" @click="box.h4=true"></div></div>
						<div class="h5">四川省骨科医院<div class="lpoint" @click="box.h5=true"></div></div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="school">
						<div class="tips"></div>

						<div class="s9">石室双楠实验学校 <div class="lpoint" @click="box.s9=true"></div></div>
						<div class="s10">高新外国语学校 <div class="lpoint" @click="box.s10=true"></div></div>
						<div class="s11">棕北中学西区实验学校 <div class="rpoint" @click="box.s11=true"></div></div>

						<div class="s1">金苹果幼儿园 <div class="rpoint" @click="box.s1=true"></div></div>
						<div class="s2">丽都实验幼儿园 <div class="lpoint" @click="box.s2=true"></div></div>
						<div class="s3">尚慧国际幼儿园 <div class="rpoint" @click="box.s3=true"></div></div>
						<div class="s4">公立幼儿园、小学<br>（项目配置） <div class="lpoint" @click="box.s4=true"></div></div>
						<div class="s5">银都小学（银都校区） <div class="lpoint" @click="box.s5=true"></div></div>
						<div class="s6">红牌楼小学 <div class="rpoint" @click="box.s6=true"></div></div>
						<div class="s7">西川中学 <div class="lpoint" @click="box.s7=true"></div></div>
						<div class="s8">成都第43中学 <div class="rpoint" @click="box.s8=true"></div></div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="park">
						<div class="tips"></div>
						<div class="p5">浣花溪公园 <div class="lpoint" @click="box.p5=true"></div></div>
						<div class="p1">绿化公园（项目配建）<div class="lpoint" @click="box.p1=true"></div></div>
						<div class="p2">红牌楼市政公园 <div class="rpoint" @click="box.p2=true"></div></div>
						<div class="p3">神仙树公园 <div class="rpoint" @click="box.p3=true"></div></div>
						<div class="p4">丽都公园 <div class="lpoint" @click="box.p4=true"></div></div>
					</div>
				</swiper-slide>
			</swiper>
			<div class="project"></div>
    </div>
    <div class="guide" v-if="guide" @click="hideGuide"></div>

		<div class="box-panel" v-if="box.e1">
			<div class="bg e1">
				<a href="javascript:;" @click="box.e1=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.e2">
			<div class="bg e2">
				<a href="javascript:;" @click="box.e2=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.e3">
			<div class="bg e3">
				<a href="javascript:;" @click="box.e3=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.e4">
			<div class="bg e4">
				<a href="javascript:;" @click="box.e4=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.e5">
			<div class="bg e5">
				<a href="javascript:;" @click="box.e5=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>

		<div class="box-panel" v-if="box.h1">
			<div class="bg h1">
				<a href="javascript:;" @click="box.h1=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.h2">
			<div class="bg h2">
				<a href="javascript:;" @click="box.h2=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.h3">
			<div class="bg h3">
				<a href="javascript:;" @click="box.h3=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.h4">
			<div class="bg h4">
				<a href="javascript:;" @click="box.h4=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.h5">
			<div class="bg h5">
				<a href="javascript:;" @click="box.h5=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>

		<div class="box-panel" v-if="box.s1">
			<div class="bg s1">
				<a href="javascript:;" @click="box.s1=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s2">
			<div class="bg s2">
				<a href="javascript:;" @click="box.s2=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s3">
			<div class="bg s3">
				<a href="javascript:;" @click="box.s3=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s4">
			<div class="bg s4">
				<a href="javascript:;" @click="box.s4=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s5">
			<div class="bg s5">
				<a href="javascript:;" @click="box.s5=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s6">
			<div class="bg s6">
				<a href="javascript:;" @click="box.s6=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s7">
			<div class="bg s7">
				<a href="javascript:;" @click="box.s7=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s8">
			<div class="bg s8">
				<a href="javascript:;" @click="box.s8=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s9">
			<div class="bg s9">
				<a href="javascript:;" @click="box.s9=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s10">
			<div class="bg s10">
				<a href="javascript:;" @click="box.s10=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.s11">
			<div class="bg s11">
				<a href="javascript:;" @click="box.s11=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>

		<div class="box-panel" v-if="box.p1">
			<div class="bg p1">
				<a href="javascript:;" @click="box.p1=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.p2">
			<div class="bg p2">
				<a href="javascript:;" @click="box.p2=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.p3">
			<div class="bg p3">
				<a href="javascript:;" @click="box.p3=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.p4">
			<div class="bg p4">
				<a href="javascript:;" @click="box.p4=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
		<div class="box-panel" v-if="box.p5">
			<div class="bg p5">
				<a href="javascript:;" @click="box.p5=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

export default {
	name: "Zone",
	data (){
		return{
			zone: 0,
			guide: false,
			box:{
				e1: false,
				e2: false,
				e3: false,
				e4: false,
				e5: false,
				h1: false,
				h2: false,
				h3: false,
				h4: false,
				h5: false,
				s1: false,
				s2: false,
				s3: false,
				s4: false,
				s5: false,
				s6: false,
				s7: false,
				s8: false,
				s9: false,
				s10: false,
				s11: false,
				p1: false,
				p2: false,
				p3: false,
				p4: false,
				p5: false,
			},
			swiperOption: {
				on: {
					slideChange: (swiper) => {
						this.zone = swiper.activeIndex
					}
				}
			}
		}
	},
	components: {
		Swiper,
		SwiperSlide
	},
	computed: mapState([
    'openMenu'
  ]),
  mounted(){
    this.guide = localStorage.getItem('zone_guide') ? false : true
  },
	methods:{
		toSlide(index) {
			this.zone = index
      this.$refs.mySwiper.$swiper.slideTo(index, 300)
    },
    hideGuide(){
      this.guide = false
      localStorage.setItem('zone_guide',true)
    }
	}
};
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";

.zone-page {
  position: absolute;
  // width: 100%;
  // height: 100vh;
	@include bg("zone/bg.png", 100%, 100vh, top center);
	overflow: hidden;

  .bg{
		.tips{
				position: absolute;
				bottom: 115px;
				left: 20px;
				@include bg('zone/bg_txt.png', 337px,22px);
		}
		// .radar{
		//     position: absolute;
		//     top: 156px;
		//     left: 93px;
		//     @include bg('zone/radar.png', 144px,134px);
		//     animation: halo 2s infinite ease-out;

		//     &.r2{
		//         animation: halo2 2s 1s infinite ease-out;
		//     }
		// }
	}

	.project{
		position: absolute;
		top: 144px;
		left: 72px;
		@include bg("zone/project.png", 203px,123px);
		// z-index: 999;
	}

  .metro {
    position: relative;
    @include bg("zone/metro.png", 100%, 100vh, top center);

		.line{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			animation-name: line;
			animation-duration: 1s;
			animation-iteration-count:infinite;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: top left;
		}
		.station{
			position: absolute;
			top: 0;
			left: 0;
			@include bg("zone/m1.png", 100%, 100vh, top center);
			// z-index: 1;
		}

		.tips{
			position: absolute;
			bottom: 150px;
			left: 13px;
			@include bg("zone/m_tips.png",243px,116px);
		}
  }
  .emporia {
    position: relative;
    @include bg("zone/emporia.png", 100%, 100vh, top center);
		overflow: hidden;

		.radar1{
			position: absolute;
			top: 30px;
			left: 20px;
			width: 180px;
			height: 180px;
			background: rgba(208, 158, 81, 0.4);
			opacity: 0.3;
			border: 1px dashed #F49D36;
			border-radius: 50%;

			animation: zoomrotate 4s linear infinite;
		}
		.radar2{
			position: absolute;
			top: 142px;
			left: 44px;
			width: 210px;
			height: 210px;
			background: rgba(205, 109, 105, 0.4);
			opacity: 0.3;
			border: 1px dashed #C96C68;
			border-radius: 50%;

			animation: zoomrotate 4s linear infinite;
		}
		.radar3{
			position: absolute;
			top: 250px;
			left: 208px;
			width: 166px;
			height: 166px;
			background: rgba(106, 69, 139, 0.4);
			opacity: 0.3;
			border: 1px dashed #7F72D7;
			border-radius: 50%;

			animation: zoomrotate 4s linear infinite;
		}
		.e1,.e2,.e3,.e4,.e5{
			position: absolute;
			color: #fff;
			font-size: 7px;
			font-weight: 600;

			.point{
				position: absolute;
				bottom: -3px;
				@include bg("zone/e1.png",30px,55px);
				animation: zoom 2s infinite;
			}
		}
		.e6,.e7,.e8,.e9{
			position: absolute;
			color: #fff;
			font-size: 7px;
			font-weight: 600;

			.point{
				position: absolute;
				bottom: -3px;
				@include bg("zone/e1.png",30px,55px);
				// animation: zoom 2s infinite;
			}
		}
		.e1{
			top: 138px;
			left: 160px;
			// @include bg('zone/e1.png',26px,17px);
			.point{
				right: -25px;
			}
		}
		.e2{
			top: 96px;
			left: 115px;
			// @include bg('zone/e2.png',51px,17px);
			// animation: zoom 2s infinite;
			.point{
				right: -25px;
			}
		}
		.e3{
			top: 175px;
			left: 19px;
			// @include bg('zone/e3.png',43px,19px);
			// animation: zoom 2s infinite;
			.point{
				left: -25px;
			}
		}
		.e4{
			top: 181px;
			left: 202px;
			// @include bg('zone/e4.png',33px,40px);
			// animation: zoom 2s infinite;
			span{
				display: inline-block;
				transform: rotate(45deg) translateY(-5px);
				transform-origin: bottom right;
			}
			.point{
				right: -25px;
			}
		}
		.e5{
			top: 260px;
			left: 133px;
			// @include bg('zone/e5.png',34px,40px);
			// animation: zoom 2s infinite;
			.point{
				right: -25px;
			}
		}

		.e6{
			top: 101px;
			left: 250px;
			.point{
				right: -25px;
			}
		}
		.e7{
			top: 114px;
			left: 267px;
			.point{
				right: -25px;
			}
		}
		.e8{
			top: 116px;
			left: 169px;
			.point{
				right: -25px;
			}
		}
		.e9{
			top: 90px;
			left: 61px;
			.point{
				right: -25px;
			}
		}

		.tips{
			position: absolute;
			bottom: 150px;
			left: 20px;
			@include bg("zone/e_tips.png",207px,76px);
		}
  }
  .hospital {
    position: relative;
		width: 100%;
		height: 100vh;
    // @include bg("zone/hospital.png", 100%, 100vh, top center);

		.h1,.h2,.h3,.h4,.h5{
			position: absolute;
			color: #fff;
			font-size: 7px;
			font-weight: 600;

			.lpoint,.rpoint{
				position: absolute;
				bottom: -3px;
				@include bg("zone/e1.png",30px,55px);
				animation: zoom 2s infinite;
			}
			.lpoint{
				left: -25px;
			}
			.rpoint{
				right: -25px;
			}
		}
		// .h5{
		// 	position: absolute;
		// 	color: #fff;
		// 	font-size: 7px;
		// 	font-weight: 600;

		// 	.lpoint{
		// 		position: absolute;
		// 		bottom: -3px;
		// 		left: -25px;
		// 		@include bg("zone/e1.png",30px,55px);
		// 		// animation: zoom 2s infinite;
		// 	}
		// }

		.h1{
			top: 128px;
			left: 122px;
		}
		.h2{
			top: 139px;
			left: 202px;
		}
		.h3{
			top: 170px;
			left: 280px;
		}
		.h4{
			top: 145px;
			left: 332px;
		}
		.h5{
			top: 98px;
			left: 243px;
		}


		.tips{
			position: absolute;
			bottom: 150px;
			left: 20px;
			@include bg("zone/h_tips.png",236px,92px);
		}
  }
  .school {
    position: relative;
		width: 100%;
		height: 100vh;
    // @include bg("zone/school.png", 100%, 100vh, top center);

		.s1,.s2,.s3,.s4,.s5,.s6,.s7,.s8,.s9,.s10,.s11{
			position: absolute;
			color: #fff;
			font-size: 7px;
			font-weight: 600;

			.lpoint,.rpoint{
				position: absolute;
				bottom: -3px;
				@include bg("zone/s1.png",30px,55px);
				animation: zoom 2s infinite;
			}
			.lpoint{
				left: -25px;
			}
			.rpoint{
				right: -25px;
			}
		}
		// .s10,.s9{
		// 	position: absolute;
		// 	color: #fff;
		// 	font-size: 7px;
		// 	font-weight: 600;

		// 	.rpoint{
		// 		position: absolute;
		// 		bottom: -3px;
		// 		left: -25px;
		// 		@include bg("zone/e1.png",30px,55px);
		// 		// animation: zoom 2s infinite;
		// 	}
		// }

		.s6,.s5{
			.lpoint,.rpoint{
				@include bg("zone/s3.png",30px,55px);
			}
		}
		.s9,.s7,.s8,.s10,.s11{
			.lpoint,.rpoint{
				@include bg("zone/s2.png",30px,55px);
			}
		}

		.s1{
			top: 344px;
			left: 173px;
		}
		.s2{
			top: 221px;
			left: 257px;
		}
		.s3{
			top: 318px;
			left: 118px;
		}
		.s4{
			top: 195px;
			left: 233px;
		}
		.s5{
			top: 366px;
			left: 250px;
		}
		.s6{
			top: 141px;
			left: 201px;
		}
		.s7{
			top: 174px;
			left: 284px;
		}
		.s8{
			top: 184px;
			left: 84px;
		}

		.s9{
			top: 104px;
			left: 236px;
		}
		.s10{
			top: 249px;
			left: 281px;
		}
		.s11{
			top: 94px;
			left: 108px;
		}

		.tips{
			position: absolute;
			bottom: 150px;
			left: 20px;
			@include bg("zone/s_tips.png",257px,132px);
		}
  }
  .park {
    position: relative;
    @include bg("zone/park.png", 100%, 100vh, top center);

		.p1,.p2,.p3,.p4,.p5{
			position: absolute;
			color: #fff;
			font-size: 7px;
			font-weight: 600;

			.lpoint,.rpoint{
				position: absolute;
				bottom: -3px;
				@include bg("zone/p1.png",30px,55px);
				animation: zoom 2s infinite;
			}
			.lpoint{
				left: -25px;
			}
			.rpoint{
				right: -25px;
			}
		}
		// .p5{
		// 	position: absolute;
		// 	color: #fff;
		// 	font-size: 7px;
		// 	font-weight: 600;

		// 	.lpoint{
		// 		position: absolute;
		// 		bottom: -3px;
		// 		left: -25px;
		// 		@include bg("zone/p1.png",30px,55px);
		// 		// animation: zoom 2s infinite;
		// 	}
		// }

		.p1{
			top: 210px;
			left: 215px;
		}
		.p2{
			top: 122px;
			left: 116px;
		}
		.p3{
			top: 358px;
			left: 197px;
		}
		.p4{
			top: 204px;
			left: 274px;
		}
		.p5{
			top: 67px;
			left: 234px;
		}

		.tips{
			position: absolute;
			bottom: 150px;
			left: 20px;
			@include bg("zone/p_tips.png",213px,88px);
		}
  }

  .guide{
    position: fixed;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100vh;
    @include bg('tips2.png',100vw,100vh);
    background-size: 347px 255px;
    z-index: 999;
  }

	.box-panel{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0,0,0,0.8);
		z-index: 9999;
		.bg{
			position: absolute;
			width: 344px;
			height: 478px;
			top: 10vh;
			left: 16px;
			a{
				position: absolute;
				top: 10px;
				right: 10px;
				width: 27px;
				height: 27px;
				background: rgba(0,0,0,0.6);
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				.iconfont{
					color: #fff;
				}
			}
		}
		.e1{
			@include bg('zone/e1_pic.png',344px,478px);
		}
		.e2{
			@include bg('zone/e2_pic.png',344px,478px);
		}
		.e3{
			@include bg('zone/e3_pic.png',344px,478px);
		}
		.e4{
			@include bg('zone/e4_pic.png',344px,478px);
		}
		.e5{
			@include bg('zone/e5_pic.png',344px,478px);
		}

		.h1{
			@include bg('zone/h1_pic.png',344px,478px);
		}
		.h2{
			@include bg('zone/h2_pic.png',344px,478px);
		}
		.h3{
			@include bg('zone/h3_pic.png',344px,478px);
		}
		.h4{
			@include bg('zone/h4_pic.png',344px,478px);
		}
		.h5{
			@include bg('zone/h5_pic.png',344px,478px);
		}

		.s1{
			@include bg('zone/s1_pic.png',344px,478px);
		}
		.s2{
			@include bg('zone/s2_pic.png',344px,478px);
		}
		.s3{
			@include bg('zone/s3_pic.png',344px,478px);
		}
		.s4{
			@include bg('zone/s4_pic.png',344px,478px);
		}
		.s5{
			@include bg('zone/s5_pic.png',344px,478px);
		}
		.s6{
			@include bg('zone/s6_pic.png',344px,478px);
		}
		.s7{
			@include bg('zone/s7_pic.png',344px,478px);
		}
		.s8{
			@include bg('zone/s8_pic.png',344px,478px);
		}
		.s9{
			@include bg('zone/s9_pic.png',344px,478px);
		}
		.s10{
			@include bg('zone/s10_pic.png',344px,478px);
		}
		.s11{
			@include bg('zone/s11_pic.png',344px,478px);
		}

		.p1{
			@include bg('zone/p1_pic.png',344px,478px);
		}
		.p2{
			@include bg('zone/p2_pic.png',344px,478px);
		}
		.p3{
			@include bg('zone/p3_pic.png',344px,478px);
		}
		.p4{
			@include bg('zone/p4_pic.png',344px,478px);
		}
		.p5{
			@include bg('zone/p5_pic.png',344px,478px);
		}
	}
}
@keyframes zoom{
	0% {
    transform: scale(1);
		transform-origin: 15px 50px;
  }
  50% {
    transform: scale(1.2);
		transform-origin: 15px 50px;
  }
  100% {
    transform: scale(1);
		transform-origin: 15px 50px;
  }
}
// @keyframes halo {
//   0% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: scale(1.2);
//   }
// }
// @keyframes halo2 {
//   0% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: scale(1.2);
//   }
// }
// @keyframes halo3 {
//   0% {
//     opacity: 0;
//     transform: scale(1);
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: scale(1.5);
//   }
// }
// @keyframes halo4 {
//   0% {
//     opacity: 0;
//     transform: scale(1);
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: scale(1.5);
//   }
// }
@keyframes line{
	0%{
		@include bg-img('zone/m2.png');
	}
	50%{
		@include bg-img('zone/m3.png');
	}
	100%{
		@include bg-img('zone/m2.png');
	}
}
@keyframes zoomrotate{
	0%{
		transform: scale(1) rotate(0);
	}
	50%{
		transform: scale(1.1) rotate(180deg);
	}
	100%{
		transform: scale(1) rotate(360deg);
	}
}
</style>
